.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-main {
  background-color: #eaeaea;
  flex-grow: 1;
  color: black;
}

.App-options {
  padding: 2rem 0;
  font-size: 1.5rem;
}

.App-tile-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.App-footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-options select {
  padding: 0.5rem;
  font-size: 1.5rem;
}

.user-select {
  display: flex;
  margin: auto;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user-select input {
  padding-bottom: "1em";
}
