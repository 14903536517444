.meet-tile {
  background-color: lightslategray;
  grid-column: span 1;
  grid-row: span 1;
  max-width: 500px;
  margin: 1rem 2rem;
}

.tile-button {
  margin: 10px 5px;
}

.meet-img {
  width: 500px;
  height: 330px;
  object-fit: cover;
}

.image-container {
  position: relative;
  width: 500px;
  height: 330px;
}

.img-btn {
  position: absolute;
  background-color: #eaeaea;
  top: 2%;
  left: 85%;
}
